const knownFonts = {
  Biotif: "https://static.withcardinal.com/webfonts/biotif/biotif.css",
  Inter: "https://static.withcardinal.com/webfonts/inter/v3.19/inter.css",
};

/**
 * Loads the given fonts by adding link tags for their files dynamically.
 *
 * @param  {...string} families - the font families to load
 */
export function fonts(...families) {
  for (const family of families) {
    const link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("href", knownFonts[family]);
    link.setAttribute("data-client-router-sticky", "");
    document.head.appendChild(link);
  }
}

/**
 * Looks up a known font family's stylesheet url
 *
 * @param {string} family - the name of the font to return the url for
 * @returns The url of the font, or undefined if it isn't a known font
 */
export function fontUrl(family) {
  return knownFonts[family];
}
