import { replaceDocument } from "@with-cardinal/client-router";
import { Fx } from "@with-cardinal/fx";
import { ClientRouter } from "@with-cardinal/client-router";

export class BetterForm extends Fx {
  attach() {
    this.form = this.querySelector("form");
    this.listen(this.form, "submit", (evt) => this.onSubmit(evt));
  }

  // called when a form submission is successful, i.e. status < 400
  // eslint-disable-next-line no-unused-vars
  async onSuccess(resp) {
    return;
  }

  // called when a form submission is unsuccessful, i.e. status >= 400
  // eslint-disable-next-line no-unused-vars
  async onError(resp) {
    return;
  }

  async onSubmit(evt) {
    evt.preventDefault();

    this.disableSubmit();

    const resp = await fetch(this.form.action, {
      method: "post",
      body: new URLSearchParams(new FormData(this.form)),
    });

    this.enableSubmit();

    if (resp.status < 400) {
      this.onSuccess(resp);
    } else {
      this.onError(resp);
    }

    if (resp.headers.has("Content-Location")) {
      ClientRouter.push(resp.headers.get("Content-Location"));
    } else {
      replaceDocument(resp);
    }
  }

  submitButtons() {
    return this.form.querySelectorAll("button[type='submit']");
  }

  disableSubmit() {
    for (const el of this.submitButtons()) {
      el.setAttribute("disabled", "true");
    }
  }

  enableSubmit() {
    for (const el of this.submitButtons()) {
      el.removeAttribute("disabled");
    }
  }
}
